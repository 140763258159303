import { defineStore } from "pinia";
import { ref } from "vue";
import { ErrorCode } from "../../../api/types";
import { Names } from "../../types";

export const useGlobalStore = defineStore(Names.Global, () => {
  // 错误码
  const errorCode = ref<ErrorCode>(ErrorCode.Default);
  // 更新错误码
  const setErrorCode = (code: ErrorCode) => {
    errorCode.value = code;
  };

  return {
    errorCode,
    setErrorCode,
  };
});

