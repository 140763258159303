import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    {
      path: "/:businessType/:businessId",
      name: "index",
      component: () => import("/src/views/home.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/success/:businessType",
      name: "success",
      component: () => import("/src/views/result.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/",
      name: "defaultPage",
      component: () => import("/src/views/defaultPage.vue"),
    },
  ],
});
export default router;
