import { createApp } from "vue";
import "./style.less";
import App from "./App.vue";
import router from "./router";
import piniaStore from "./store";
import "./api/request";
import aegisMonitor, { AegisSdk } from "@hiijs/aegis-monitor";

const app = createApp(App);

// @ts-ignore
app.config.errorHandler = function (err, vm, info) {
  console.log(err);
  aegisMonitor.report("全局错误处理", {
    level: AegisSdk.logType.ERROR,
    ext1: err,
    ext2: info,
  });
};

app.use(router).use(piniaStore).mount("#app");
