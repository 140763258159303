import axios from "axios";
import type { InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { useGlobalStore } from "../store";

if (import.meta.env.VITE_API_BASE_URL) {
  axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;
  // axios.defaults.baseURL = 'http://192.168.0.193:9092/'
}
export interface HttpResponse<T = unknown> {
  requestId: number;
  code: number;
  data: T;
  msg?: string;
}
axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// add response interceptors
axios.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    const res = response.data;
    if (res.code !== 200) {
      const globalStore = useGlobalStore();
      // 设置错误码
      globalStore.setErrorCode(res.code);
      return Promise.reject(res.msg);
    }
    return res;
  },
  async (error) => {
    return Promise.reject(error);
  }
);
